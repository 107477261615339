<template>
  <div>
    <Card style="margin-bottom: 16px" dis-hover>
      <Form ref="formData" :model="formData" label-position="left" :label-width="60" inline>
        <Row class="form1">
          <Col :xs="10" :sm="12" :md="24" :xxl="24" style="text-align: left">
            <FormItem label="任务名:" prop="name">
              <Select v-model="formData.name" clearable style="min-width:150px">
                <Option v-for="item in names" :value="item" :key="item._index">{{ item }}</Option>
              </Select>
            </FormItem>
            <FormItem label="任务ID:" prop="id">
              <Select v-model="formData.id" clearable style="min-width:150px">
                <Option v-for="item in ids" :value="item" :key="item._index">{{ item }}</Option>
              </Select>
            </FormItem>
            <FormItem label="时间段:" prop="time">
              <DatePicker v-model="formData.time" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" placeholder="时间段" style="width: 320px"></DatePicker>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="submit">查 看</Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Card>
    <Row style="margin-bottom: 16px">
      <Col span="24">
        <Card dis-hover>
          <p slot="title" style="text-align: left">性能概览</p>
          <div id="overview" style="width: 100%;height: 100px"></div>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col span="24" style="margin-bottom: 16px">
        <Card dis-hover>
          <p slot="title" style="text-align: left">任务趋势</p>
          <Row>
            <Col span="12" style="margin-bottom: 16px;text-align: left;">
              时间粒度:
              <RadioGroup v-model="taskTrendData.interval" type="button">
                <Radio label="toStartOfMinute(t) t">1分钟</Radio>
                <Radio label="toStartOfFiveMinute(t) t">5分钟</Radio>
                <Radio label="toStartOfTenMinutes(t) t">10分钟</Radio>
                <Radio label="toStartOfInterval(t, INTERVAL 30 minute) t">30分钟</Radio>
                <Radio label="toStartOfHour(t) t">1小时</Radio>
              </RadioGroup>
            </Col>
          </Row>
          <Row>
            <Col span="12">
              传输速度(KB/s)
              <div id="speedTrend" style="width: 100%;height: 300px"></div>
            </Col>
            <Col span="12">
              成功率(%)
              <div id="succRate" style="width: 100%;height: 300px"></div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    <Row style="margin-bottom: 16px">
      <Col span="24">
        <Card dis-hover>
          <p slot="title" style="text-align: left">Top5</p>
          <Row>
            <Col span="12">
              平均速度Top5
              <div id="slowlyTop5" style="width: 100%;height: 300px"></div>
            </Col>
            <Col span="12">
              错误类型Top5
              <el-tooltip class="icon-item" effect="light" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p style="color: red">错误号说明</p>
                  <p>0: 未知错误</p>
                  <p>-1: 未知HOST地址</p>
                  <p>-2: TCP连接被拒绝</p>
                  <p>-3: TCP连接超时</p>
                  <p>-4: TCP请求超时</p>
                  <p>-5: DNS解析超时</p>
                  <p>-6: 302跳转次数超限</p>
                  <p>-7: RESPONSE消息头部没有LOCATION字段</p>
                  <p>-8: http返回码与期望返回码不匹配</p>
                  <p>-9: 消息体下载超时</p>
                </div>
              </el-tooltip>
              <div id="errorTop5" style="width: 100%;height: 300px"></div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    <Row style="margin-bottom: 16px">
      <Col span="24">
        <Card dis-hover>
          <p slot="title" style="text-align: left">省份 详情</p>
          <Form ref="formData" label-position="left" inline>
            <Row>
              <Col :xs="2" :sm="4" :md="6" :xxl="4" style="text-align: left">
                <FormItem label="" prop="name">
                  <Select v-model="provinceSelect" multiple style="min-width:150px; margin-left: 0%;" @on-change="handleSelect">
                    <Option v-for="item in provinceList" :value="item.value" :key="item._index">{{ item.value }}</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Table size="small" highlight-row :columns="provinceColumns" :data="overviewData.province_details">
            <template slot-scope="{ row }" slot="size_download">
              <span>{{ row.avg_size_download }}KB</span>
            </template>
            <template slot-scope="{ row }" slot="download_speed">
              <span :class="[row.avg_download_speed < 0.8 ? 'error' : row.avg_download_speed > 0.8 && row.avg_download_speed < 2 ? 'warning' : 'info']">{{ row.avg_download_speed }}KB/s</span>
            </template>
            <template slot-scope="{ row }" slot="time_total">
              <span :class="[row.avg_time_total < 20 ? 'info' : row.avg_time_total > 20 && row.avg_time_total < 40 ? 'warning' : 'error']">{{ row.avg_time_total }}s</span>
            </template>
            <template slot-scope="{ row }" slot="time_pre_transfer">
              <span :class="[row.avg_time_pre_transfer < 1 ? 'info' : row.avg_time_pre_transfer > 1 && row.avg_time_pre_transfer < 2.5 ? 'warning' : 'error']">{{ row.avg_time_pre_transfer }}s</span>
            </template>
            <template slot-scope="{ row }" slot="time_dns">
              <span :class="[row.avg_time_name_lookup < 0.8 ? 'info' : row.avg_time_name_lookup > 0.8 && row.avg_time_name_lookup < 0.15 ? 'warning' : 'error']">{{ row.avg_time_name_lookup }}s</span>
            </template>
            <template slot-scope="{ row }" slot="time_download">
              <span :class="[row.avg_time_download < 0.8 ? 'info' : row.avg_time_download > 0.8 && row.avg_time_download < 0.15 ? 'warning' : 'error']">{{ row.avg_time_download }}s</span>
            </template>
            <template slot-scope="{ row }" slot="succ_rate">
              <span :class="[ row.succ_rate < 60 ? 'error' : row.succ_rate > 60 && row.succ_rate * 100 < 90 ? 'warning' : 'info']">{{ row.succ_rate }}%</span>
            </template>
            <template slot-scope="{ row }" slot="details">
              <a @click.prevent="showDetail(row)">详 情</a>
            </template>
          </Table>
        </Card>
      </Col>
    </Row>
    <Row style="margin-bottom: 16px">
      <Col span="24">
        <Card dis-hover>
          <p slot="title" style="text-align: left">省份-运营商 详情</p>
          <Form ref="formData" label-position="left" inline>
            <Row>
              <Col :xs="2" :sm="12" :md="14" :xxl="12" style="text-align: left">
                <FormItem label="" prop="name">
                  <Select v-model="provinceIspProvinceSelect" multiple style="min-width:150px; margin-left: 0%;" @on-change="handleProvinceIspSelect">
                    <Option v-for="item in provinceIspProvinceList" :value="item" :key="item._index">{{ item }}</Option>
                  </Select>
                </FormItem>
                <FormItem label="" prop="name">
                  <Select v-model="provinceIspSelect" multiple style="min-width:150px; margin-left: 0%;" @on-change="handleProvinceIspSelect">
                    <Option v-for="item in provinceIspList" :value="item" :key="item._index">{{ item }}</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Table size="small" highlight-row :columns="provinceIspColumns" :data="overviewData.province_isp_details">
            <template slot-scope="{ row }" slot="size_download">
              <span>{{ row.avg_size_download }}KB</span>
            </template>
            <template slot-scope="{ row }" slot="download_speed">
              <span :class="[row.avg_download_speed < 0.8 ? 'error' : row.avg_download_speed > 0.8 && row.avg_download_speed < 2 ? 'warning' : 'info']">{{ row.avg_download_speed }}KB/s</span>
            </template>
            <template slot-scope="{ row }" slot="time_total">
              <span :class="[row.avg_time_total < 20 ? 'info' : row.avg_time_total > 20 && row.avg_time_total < 40 ? 'warning' : 'error']">{{ row.avg_time_total }}s</span>
            </template>
            <template slot-scope="{ row }" slot="time_pre_transfer">
              <span :class="[row.avg_time_pre_transfer < 1 ? 'info' : row.avg_time_pre_transfer > 1 && row.avg_time_pre_transfer < 2.5 ? 'warning' : 'error']">{{ row.avg_time_pre_transfer }}s</span>
            </template>
            <template slot-scope="{ row }" slot="time_dns">
              <span :class="[row.avg_time_name_lookup < 0.8 ? 'info' : row.avg_time_name_lookup > 0.8 && row.avg_time_name_lookup < 0.15 ? 'warning' : 'error']">{{ row.avg_time_name_lookup }}s</span>
            </template>
            <template slot-scope="{ row }" slot="time_download">
              <span :class="[row.avg_time_download < 0.8 ? 'info' : row.avg_time_download > 0.8 && row.avg_time_download < 0.15 ? 'warning' : 'error']">{{ row.avg_time_download }}s</span>
            </template>
            <template slot-scope="{ row }" slot="succ_rate">
              <span :class="[ row.succ_rate < 60 ? 'error' : row.succ_rate > 60 && row.succ_rate * 100 < 90 ? 'warning' : 'info']">{{ row.succ_rate }}%</span>
            </template>
            <template slot-scope="{ row }" slot="details">
              <a @click.prevent="showDetail(row)">详 情</a>
            </template>
          </Table>
        </Card>
      </Col>
    </Row>
    <Drawer
        title="详情"
        v-model="showDetails"
        width="50%"
        :mask-closable="true"
        :styles="styles"
    >
      <Row>
        <Col span="24" style="margin-bottom: 16px">
          时间粒度:
          <RadioGroup v-model="detailData.interval" type="button">
            <Radio label="toStartOfMinute(t) t">1分钟</Radio>
            <Radio label="toStartOfFiveMinute(t) t">5分钟</Radio>
            <Radio label="toStartOfTenMinutes(t) t">10分钟</Radio>
            <Radio label="toStartOfInterval(t, INTERVAL 30 minute) t">30分钟</Radio>
            <Radio label="toStartOfHour(t) t">1小时</Radio>
          </RadioGroup>
        </Col>
      </Row>
      <Row>
        <Col span="24" style="margin-bottom: 16px">
          <h3>传输速度(KB/s)</h3>
          <div id="speedTrendDetails" style="width: 100%;height: 200px"></div>
        </Col>
      </Row>
      <Row>
        <Col span="24" style="margin-bottom: 16px">
          <h3>成功率(%)</h3>
          <div id="succRateDetails" style="width: 100%;height: 200px"></div>
        </Col>
      </Row>
      <Row>
        <Col span="24" style="margin-bottom: 16px">
          <h3>下载大小(KB)</h3>
          <div id="sizeDownload" style="width: 100%;height: 200px"></div>
        </Col>
      </Row>
      <Row>
        <Col span="24" style="margin-bottom: 16px">
          <h3>总耗时(s)</h3>
          <div id="timeTotal" style="width: 100%;height: 200px"></div>
        </Col>
      </Row>
      <Row>
        <Col span="24" style="margin-bottom: 16px">
          <h3>首包时间(s)</h3>
          <div id="timePreTransfer" style="width: 100%;height: 200px"></div>
        </Col>
      </Row>
      <Row>
        <Col span="24" style="margin-bottom: 16px">
          <h3>DNS时间(s)</h3>
          <div id="timeNameLookUp" style="width: 100%;height: 200px"></div>
        </Col>
      </Row>
      <Row>
        <Col span="24" style="margin-bottom: 16px">
          <h3>下载时间(s)</h3>
          <div id="timeDownload" style="width: 100%;height: 200px"></div>
        </Col>
      </Row>
    </Drawer>
  </div>
</template>

<script>
import {ckquery, get, post} from "@/api/http"
export default {
  name: "performance",
  data() {
    return {
      showDetails: false,
      detailData: {
        rowdata: {},
        interval: 'toStartOfFiveMinute(t) t',
      },
      taskTrendData: {
        interval: 'toStartOfFiveMinute(t) t',
      },
      overviewChart: null,
      succRateChart: null,
      speedTrendChart: null,
      slowlyTop5Chart: null,
      speedTrendDetailsChart: null,
      succRateDetailsChart: null,
      sizeDownloadChart: null,
      timeTotalChart: null,
      timePreTransferChart: null,
      timeNameLookUpChart: null,
      timeDownloadChart: null,
      provinceColumns: [
        {
          title: '省份',
          key: 'src_province'
        },
        {
          title: '下载大小',
          key: 'avg_size_download',
          slot: 'size_download'
        },
        {
          title: '平均速度',
          sortable: true,
          key: 'avg_download_speed',
          slot: 'download_speed'
        },
        {
          title: '平均总耗时',
          sortable: true,
          key: 'avg_time_total',
          slot: 'time_total'
        },
        {
          title: '平均首包时间',
          sortable: true,
          key: 'avg_time_pre_transfer',
          slot: 'time_pre_transfer'
        },
        {
          title: '平均dns耗时',
          sortable: true,
          key: 'avg_time_name_lookup',
          slot: 'time_dns'
        },
        {
          title: '平均下载时间',
          sortable: true,
          key : 'avg_time_download',
          slot: 'time_download'
        },
        {
          title: '成功率',
          sortable: true,
          slot: 'succ_rate'
        },
        {
          title: '监测次数',
          sortable: true,
          key: 'count'
        },
        {
          title: '详情',
          width: 80,
          slot: 'details'
        }
      ],
      provinceIspColumns: [
        {
          title: '省份',
          key: 'src_province'
        },
        {
          title: '运营商',
          key: 'src_isp'
        },
        {
          title: '下载大小',
          key: 'avg_size_download',
          slot: 'size_download'
        },
        {
          title: '平均速度',
          sortable: true,
          key: 'avg_download_speed',
          slot: 'download_speed'
        },
        {
          title: '平均总耗时',
          sortable: true,
          key: 'avg_time_total',
          slot: 'time_total'
        },
        {
          title: '平均首包时间',
          sortable: true,
          key: 'avg_time_pre_transfer',
          slot: 'time_pre_transfer'
        },
        {
          title: '平均dns耗时',
          sortable: true,
          key: 'avg_time_name_lookup',
          slot: 'time_dns'
        },
        {
          title: '平均下载时间',
          sortable: true,
          key : 'avg_time_download',
          slot: 'time_download'
        },
        {
          title: '成功率',
          sortable: true,
          slot: 'succ_rate'
        },
        {
          title: '监测次数',
          sortable: true,
          key: 'count'
        },
        {
          title: '详情',
          width: 80,
          slot: 'details'
        }
      ],
      formData: {
        id: "",
        name: "",
        time: []
      },
      names: [],
      ids: [],
      overviewData: {
        name: '',
        overview: {
          avg_speed: 0
        },
        speed_top5: [],
        error_top5: [],
        isp_speed_order: [],
        speed_trend:[],
        succ_rate_trend: [],
        province_isp_details: [],
        province_details: [],
        province_details_copy: [],
        province_isp_details_copy: [],
      },
      details: [],
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      provinceList: [],
      provinceSelect: [],
      provinceIspProvinceList: [],
      provinceIspProvinceSelect: [],
      provinceIspList: [],
      provinceIspSelect: [],
    }
  },
  methods: {
    getNames() {
      get("/api/v1/httpdetect")
          .then(resp => {
            this.names = resp.data.map(item=>{
              let {name} = item
              return name
            })
            this.ids = resp.data.map(item=>{
              let {id} = item
              return id
            })
          })
          .catch(e=>{
            console.log(e)
          })
    },
    renderOverview() {
      let {avg_download_speed, avg_size_download, avg_time_pre_transfer, succ_rate, count} = this.overviewData.overview
      let data = [
        {name: '监测次数', value: count || 0, unit: '次'},
        {name: '平均速度', value: avg_download_speed || 0, unit: 'KB/s'},
        {name: '首包用时', value: avg_time_pre_transfer  || 0, unit: 's'},
        {name: '成功率',  value: succ_rate|| 0, unit: '%'},
        {name: '传输大小', value: avg_size_download || 0, unit: 'KB'},
      ]
      let titleArr = [], seriesArr = [];
      let sCenter, TitleXy = {};
      let colors = [
        [['#19be6b', '#19be6b50']],
        [['#a4c319', '#a4c31950']],
        [['#ee8006', '#ee800650']],
        [['#4bb2d1', '#4bb2d150']],
        [['#0050a4', '#0050a450']],
      ];

      data.forEach(function (item, index) {
        sCenter = [index * 25 + 15 + '%', '50%'];

        TitleXy = {
          left: index * 80 + 30 + '%',
          top: '27%',
        };

        seriesArr.push({
          type: 'pie',
          radius: [0, 35],
          center: sCenter,
          data: [
            {
              label: {
                show: false,
              },
              value: 100,
              hoverAnimation: false,
              itemStyle: {
                color: colors[index][0][1],
                // shadowBlur: 5,
                shadowColor: 'rgba(0, 0, 0, 0)',
                shadowOffsetX: '3',
                shadowOffsetY: '3',
              },
            },
          ],
        });

        seriesArr.push({
          name: item.name,
          type: 'pie',
          clockWise: false,
          radius: [40, 50],
          itemStyle: {
            normal: {
              color: colors[index][0][0],
              shadowColor: colors[index][0],
              shadowBlur: 0,
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          hoverAnimation: false,
          center: sCenter,
          data: [
            {
              value: item.value,
              label: {
                normal: {
                  formatter: function (params) {
                    return '{a|}\n' + params.seriesName + '\n' + params.value + item.unit;
                  },
                  lineHeight: 15,
                  position: 'center',
                  show: true,
                  textStyle: {
                    fontSize: '12',
                    fontWeight: 'bold',
                    color: colors[index][0][0],
                  },
                  rich: {
                    // a: {
                    //   width: 40,
                    //   height: 40,
                    //   // lineHeight: 50,
                    //   shadowBlur: 0,
                    //   shadowColor: '#2b2a38',
                    //   shadowOffsetX: '1',
                    //   shadowOffsetY: '1',
                    //   backgroundColor: {
                    //     image: imgSrc,
                    //   },
                    // },
                  },
                },
              },
            },
            {
              value: 0,
              name: 'invisible',
              itemStyle: {
                normal: {
                  color: colors[index][0][1],
                },
                emphasis: {
                  color: colors[index][0][1],
                },
              },
            },
          ],
        });
      });

      let option = {
        backgroundColor: '#fff',
        series: seriesArr,
      };
      this.overviewChart.setOption(option);
    },
    renderSuccRateTrend() {
      let showed = this.overviewData.succ_rate_trend.length === 0
      let XData = this.overviewData.succ_rate_trend.map(item=>{
        return item.t
      })
      let valueData = {
        data: this.overviewData.succ_rate_trend.map(item => item.succ_rate)
      }

      let maxData = 100
      let minData = 0
      if (valueData.data.length > 0) {
        let tmpMax = Math.max(...valueData.data)
        let tmpMin = Math.min(...valueData.data)
        maxData = tmpMax + (tmpMax - tmpMin) * 0.2
        minData = tmpMin - (tmpMax - tmpMin) * 0.2
      }

      let name = this.formData.name
      let option = {
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          color: ["#F58080", "#47D8BE", "#F9A589"],
          right: '2%',
          top: '2%'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          height: '80%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: XData,
          axisLine: {
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)"
            }
          },
        },
        yAxis: [{
          type: 'value',
          min: minData,
          max: maxData,
          axisLabel: {
            textStyle: {
              color: "#808695"
            }}    ,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
        }
        ],
        series: [
          {
            name: name,
            type: 'line',
            data: valueData.data,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            lineStyle: {
              normal: {
                width: 1,
                color: {
                  type: 'linear',
                  colorStops: [{
                    offset: 0,
                    color: '#19be6b' // 0% 处的颜色
                  },
                    {
                      offset: 0.4,
                      color: '#19be6b' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: '#19be6b' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: '#19be6b',
                shadowBlur: 9,
                shadowOffsetY: 3
              }
            },
            itemStyle: {
              normal: {
                color: '#19be6b',
              }
            },
            emphasis: {
              // 鼠标hover上去的时候，拐点的颜色和样式
              itemStyle: {
                color: '#19be6b', //颜色
                borderColor: '#fff', //图形的描边颜色
                borderWidth: 1, // 描边的线宽
                shadowBlur: 0.5, // 图形的阴影大小
                shadowColor: '#19be6b', // 图形的阴影颜色
              },
            },
            smooth: false
          }
        ]
      };
      this.succRateChart.setOption(option);
    },
    renderSpeedTrend() {
      let showed = this.overviewData.speed_trend.length === 0
      let XData = this.overviewData.speed_trend.map(item=>{
        return item.t
      })

      
      let valueData = {
        data: this.overviewData.speed_trend.map(item => item.avg_download_speed)
      }

      let name = this.formData.name
      let option = {
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          color: ["#F58080", "#47D8BE", "#F9A589"],
          right: '2%',
          top: '2%'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '3%',
          bottom: '3%',
          height: '80%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: XData,
          axisLine: {
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)"
            }
          },
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: "#808695"
            }}    ,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
        }
        ],
        series: [
          {
            name: name,
            type: 'line',
            data: valueData.data,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            lineStyle: {
              normal: {
                width: 1,
                color: {
                  type: 'linear',
                  colorStops: [{
                    offset: 0,
                    color: '#5cadff' // 0% 处的颜色
                  },
                    {
                      offset: 0.4,
                      color: '#5cadff' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: '#5cadff' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: '#5cadff',
                shadowBlur: 9,
                shadowOffsetY: 3
              }
            },
            itemStyle: {
              normal: {
                color: '#5cadff',
              }
            },
            emphasis: {
              // 鼠标hover上去的时候，拐点的颜色和样式
              itemStyle: {
                color: '#5cadff', //颜色
                borderColor: '#fff', //图形的描边颜色
                borderWidth: 1, // 描边的线宽
                shadowBlur: 0.5, // 图形的阴影大小
                shadowColor: '#5cadff', // 图形的阴影颜色
              },
            },
            smooth: false
          }
        ]
      };
      this.speedTrendChart.setOption(option);
    },
    renderTop5() {
      let speedShowed = this.overviewData.speed_top5.length === 0
      let slowlyTop5XData = this.overviewData.speed_top5.map(item=>{
        return item.src_isp
      })

      let slowlyTop5value = this.overviewData.speed_top5.map(item=>{
        return item.avg_download_speed
      })

      let slowlyTop5Option = {
        backgroundColor: '#fff',
        title: {
          show: speedShowed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '15%',
          right: '3%',
          left: '5%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          data: slowlyTop5XData,
          axisLine: {
            lineStyle: {
              color: '#FFFFFF'
            }
          },
          axisLabel: {
            margin: 10,
            color: '#515a6e',
            textStyle: {
              fontSize: 14
            },
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          axisLabel: {
            formatter: '{value}',
            color: '#515a6e',
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0,186,255,.6)'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.12)'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: slowlyTop5value,
          barWidth: '28px',
          itemStyle: {
            normal: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,244,255,1)' // 0% 处的颜色
              }, {
                offset: 1,
                color: 'rgba(0,77,167,1)' // 100% 处的颜色
              }], false),
              shadowColor: 'rgba(0,160,221,1)',
              shadowBlur: 4,
            }
          },
          label: {
            normal: {
              show: true,
              lineHeight: 30,
              formatter: '{c}',
              position: 'top',
              textStyle: {
                color: '#00D6F9',
                fontSize: 20
              }

            }
          }
        }]
      };
      this.slowlyTop5Chart.setOption(slowlyTop5Option);

      let errorShowed = this.overviewData.error_top5.length === 0
      let errorTop5XData = this.overviewData.error_top5 ? this.overviewData.error_top5.map(item=>{
        return item.success
      }) : []

      let errorTop5Value = this.overviewData.error_top5 ? this.overviewData.error_top5.map(item=>{
        return item.code_count
      }) : []

      console.log(errorTop5XData, errorTop5Value)
      let errorTop5Option = {
        backgroundColor: '#fff',
        title: {
          show: errorShowed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '15%',
          right: '3%',
          left: '5%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          data: errorTop5XData,
          axisLine: {
            lineStyle: {
              color: '#FFFFFF'
            }
          },
          axisLabel: {
            margin: 10,
            color: '#515a6e',
            textStyle: {
              fontSize: 14
            },
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          axisLabel: {
            formatter: '{value}',
            color: '#515a6e',
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0,186,255,.6)'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.12)'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: errorTop5Value,
          barWidth: '28px',
          itemStyle: {
            normal: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,244,255,1)' // 0% 处的颜色
              }, {
                offset: 1,
                color: 'rgba(0,77,167,1)' // 100% 处的颜色
              }], false),
              shadowColor: 'rgba(0,160,221,1)',
              shadowBlur: 4,
            }
          },
          label: {
            normal: {
              show: true,
              lineHeight: 30,
              formatter: '{c}',
              position: 'top',
              textStyle: {
                color: '#00D6F9',
                fontSize: 20
              }

            }
          }
        }]
      };
      this.errorTop5Chart.setOption(errorTop5Option);
    },
    showDetail(row) {
      this.detailData.rowdata = row;
      let formData = this.$_.cloneDeep(this.formData);
      formData.time[0] = this.$moment(formData.time[0]).format("YYYY-MM-DD HH:mm:ss");
      formData.time[1] = this.$moment(formData.time[1]).format("YYYY-MM-DD HH:mm:ss");
      let query_obj = {"name": `${formData.name}`, "task_id": `${formData.id}`}
      //`select ${this.detailData.interval}, count() total,SUM(case when http_code >= 400 or http_code < 200 then 1 else 0 end) as fail, SUM(case when http_code >= 200 or http_code < 400 then 1 else 0 end) as succ, floor(succ/(succ+fail)*100,2) succ_rate, floor(avg(size_download),2) size_download, floor(avg(download_speed),2) avg_speed, floor(avg(time_total),2) time_total, floor(avg(time_pre_transfer),2) time_pre_transfer, floor(avg(time_name_lookup),2) time_name_look_up from cassini.httpquality where name='${formData.name}' and src_province='${row.src_province}' ${row.src_isp ? 'and src_isp='+'\''+row.src_isp+'\'': ""} and t >= '${formData.time[0]}' and t <= '${formData.time[1]}' group by t order by t FORMAT JSON`
      ckquery(`SELECT ${this.detailData.interval},
       count() count,
       floor(sum(size_download)/sum(succ)/1024,2) avg_size_download,
       floor(sum(time_total)/sum(succ),2) avg_time_total,
       floor(sum(time_pre_transfer)/sum(succ),2) avg_time_pre_transfer,
       floor(sum(time_name_lookup)/sum(succ),2) avg_time_name_lookup,
       floor(sum(time_download)/sum(succ),2) avg_time_download,
       floor(sum(download_speed)/count(),2) avg_download_speed,
       floor(sum(succ)/count()*100,2) succ_rate
       FROM(select t, src_province,src_isp,
           multiIf(success != 1, 0, size_download) AS size_download,
           multiIf(success != 1, 0, download_speed) AS download_speed,
           multiIf(success != 1, 0, time_total) AS time_total,
           multiIf(success != 1, 0, time_pre_transfer) AS time_pre_transfer,
           multiIf(success != 1, 0, time_name_lookup) AS time_name_lookup,
           multiIf(success != 1, 0, time_download) AS time_download,
           multiIf(success != 1, 0,1) AS succ
       from cassini.httpquality
       where ${this.filterEmptyItem(query_obj)}
       and src_province='${row.src_province}' ${row.src_isp ? 'and src_isp='+'\''+row.src_isp+'\'': ""}
       and t >= '${formData.time[0]}' and t <= '${formData.time[1]}')
       group by t order by t FORMAT JSON`)
          .then(resp=>{
            this.details = resp.data
            this.showDetails = true
            setTimeout(() => {
              this.renderDetails(row.src_province, row.src_isp)
            }, 1)
          })
          .catch(e => console.error(e))
    },
    showDetailByInterval() {
      let formData = this.$_.cloneDeep(this.formData);
      formData.time[0] = this.$moment(formData.time[0]).format("YYYY-MM-DD HH:mm:ss");
      formData.time[1] = this.$moment(formData.time[1]).format("YYYY-MM-DD HH:mm:ss");
      let query_obj = {"name": `${formData.name}`, "task_id": `${formData.id}`}
      //`select ${this.detailData.interval}, count() total,SUM(case when http_code >= 400 or http_code < 200 then 1 else 0 end) as fail, SUM(case when http_code >= 200 or http_code < 400 then 1 else 0 end) as succ, floor(succ/(succ+fail)*100,2) succ_rate, floor(avg(size_download),2) size_download, floor(avg(download_speed),2) avg_speed, floor(avg(time_total),2) time_total, floor(avg(time_pre_transfer),2) time_pre_transfer, floor(avg(time_name_lookup),2) time_name_look_up from cassini.httpquality where name='${formData.name}' and src_province='${this.detailData.rowdata.src_province}' ${this.detailData.rowdata.src_isp ? 'and src_isp='+'\''+this.detailData.rowdata.src_isp+'\'': ""} and t >= '${formData.time[0]}' and t <= '${formData.time[1]}' group by t order by t FORMAT JSON`
      ckquery(`SELECT ${this.detailData.interval},
       count() count,
       floor(sum(size_download)/sum(succ)/1024,2) avg_size_download,
       floor(sum(time_total)/sum(succ),2) avg_time_total,
       floor(sum(time_pre_transfer)/sum(succ),2) avg_time_pre_transfer,
       floor(sum(time_name_lookup)/sum(succ),2) avg_time_name_lookup,
       floor(sum(time_download)/sum(succ),2) avg_time_download,
       floor(sum(download_speed)/count(),2) avg_download_speed,
       floor(sum(succ)/count()*100,2) succ_rate
        FROM(select t, src_province,src_isp,
            multiIf(success != 1, 0, size_download) AS size_download,
            multiIf(success != 1, 0, download_speed) AS download_speed,
            multiIf(success != 1, 0, time_total) AS time_total,
            multiIf(success != 1, 0, time_pre_transfer) AS time_pre_transfer,
            multiIf(success != 1, 0, time_name_lookup) AS time_name_lookup,
            multiIf(success != 1, 0, time_download) AS time_download,
            multiIf(success != 1, 0,1) AS succ
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
        and src_province='${this.detailData.rowdata.src_province}'
        ${this.detailData.rowdata.src_isp ? 'and src_isp='+'\''+this.detailData.rowdata.src_isp+'\'': ""}
        and t >= '${formData.time[0]}' and t <= '${formData.time[1]}')
        group by t order by t FORMAT JSON`)
          .then(resp=>{
            this.details = resp.data
            this.showDetails = true
            setTimeout(() => {
              this.renderDetails(this.detailData.rowdata.src_province, this.detailData.rowdata.src_isp)
            }, 1)
          })
          .catch(e => console.error(e))
    },
    renderDetails(src_province, src_isp) {
      let showed = this.details.length === 0
      let XData = this.details.map(item=>{
        return item.t
      })

      let speedValueData = {
        data: this.details.map(item => item.avg_download_speed)
      }

      let succRateValueData = {
        data: this.details.map(item => item.succ_rate)
      }

      let sizeDownloadValueData = {
        data: this.details.map(item => item.avg_size_download)
      }

      let timeTotalValueData = {
        data: this.details.map(item => item.avg_time_total)
      }

      let timePreTransferValueData = {
        data: this.details.map(item => item.avg_time_pre_transfer)
      }

      let timeNameLookUpValueData = {
        data: this.details.map(item => item.avg_time_name_lookup)
      }

      let timeDownloadValueData = {
        data: this.details.map(item => item.avg_time_download)
      }

      let speedOption = {
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          color: ["#F58080", "#47D8BE", "#F9A589"],
          right: '2%',
          top: '2%'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          height: '80%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: XData,
          axisLine: {
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)"
            }
          },
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: "#808695"
            }}    ,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
        }
        ],
        series: [
          {
            name: `${src_province}${src_isp ? '-'+src_isp : ''}-传输速度`,
            type: 'line',
            data: speedValueData.data,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            lineStyle: {
              normal: {
                width: 1,
                color: {
                  type: 'linear',
                  colorStops: [{
                    offset: 0,
                    color: '#19be6b' // 0% 处的颜色
                  },
                    {
                      offset: 0.4,
                      color: '#19be6b' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: '#19be6b' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: '#19be6b',
                shadowBlur: 9,
                shadowOffsetY: 3
              }
            },
            itemStyle: {
              normal: {
                color: '#19be6b',
              }
            },
            emphasis: {
              // 鼠标hover上去的时候，拐点的颜色和样式
              itemStyle: {
                color: '#19be6b', //颜色
                borderColor: '#fff', //图形的描边颜色
                borderWidth: 1, // 描边的线宽
                shadowBlur: 0.5, // 图形的阴影大小
                shadowColor: '#19be6b', // 图形的阴影颜色
              },
            },
            smooth: false
          }
        ]
      };
      let succRateOption = {
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          color: ["#F58080", "#47D8BE", "#F9A589"],
          right: '2%',
          top: '2%'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          height: '80%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: XData,
          axisLine: {
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)"
            }
          },
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: "#808695"
            }}    ,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
        }
        ],
        series: [
          {
            name: `${src_province}${src_isp ? '-'+src_isp : ''}-成功率`,
            type: 'line',
            data: succRateValueData.data,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            lineStyle: {
              normal: {
                width: 1,
                color: {
                  type: 'linear',
                  colorStops: [{
                    offset: 0,
                    color: '#19be6b' // 0% 处的颜色
                  },
                    {
                      offset: 0.4,
                      color: '#19be6b' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: '#19be6b' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: '#19be6b',
                shadowBlur: 9,
                shadowOffsetY: 3
              }
            },
            itemStyle: {
              normal: {
                color: '#19be6b',
              }
            },
            emphasis: {
              // 鼠标hover上去的时候，拐点的颜色和样式
              itemStyle: {
                color: '#19be6b', //颜色
                borderColor: '#fff', //图形的描边颜色
                borderWidth: 1, // 描边的线宽
                shadowBlur: 0.5, // 图形的阴影大小
                shadowColor: '#19be6b', // 图形的阴影颜色
              },
            },
            smooth: false
          }
        ]
      };
      let sizeDownloadOption = {
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          color: ["#F58080", "#47D8BE", "#F9A589"],
          right: '2%',
          top: '2%'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          height: '80%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: XData,
          axisLine: {
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)"
            }
          },
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: "#808695"
            }}    ,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
        }
        ],
        series: [
          {
            name: `${src_province}${src_isp ? '-'+src_isp : ''}-下载大小`,
            type: 'line',
            data: sizeDownloadValueData.data,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            lineStyle: {
              normal: {
                width: 1,
                color: {
                  type: 'linear',
                  colorStops: [{
                    offset: 0,
                    color: '#19be6b' // 0% 处的颜色
                  },
                    {
                      offset: 0.4,
                      color: '#19be6b' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: '#19be6b' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: '#19be6b',
                shadowBlur: 9,
                shadowOffsetY: 3
              }
            },
            itemStyle: {
              normal: {
                color: '#19be6b',
              }
            },
            emphasis: {
              // 鼠标hover上去的时候，拐点的颜色和样式
              itemStyle: {
                color: '#19be6b', //颜色
                borderColor: '#fff', //图形的描边颜色
                borderWidth: 1, // 描边的线宽
                shadowBlur: 0.5, // 图形的阴影大小
                shadowColor: '#19be6b', // 图形的阴影颜色
              },
            },
            smooth: false
          }
        ]
      };
      let timeTotalOption = {
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          color: ["#F58080", "#47D8BE", "#F9A589"],
          right: '2%',
          top: '2%'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          height: '80%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: XData,
          axisLine: {
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)"
            }
          },
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: "#808695"
            }}    ,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
        }
        ],
        series: [
          {
            name: `${src_province}${src_isp ? '-'+src_isp : ''}-总耗时`,
            type: 'line',
            data: timeTotalValueData.data,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            lineStyle: {
              normal: {
                width: 1,
                color: {
                  type: 'linear',
                  colorStops: [{
                    offset: 0,
                    color: '#19be6b' // 0% 处的颜色
                  },
                    {
                      offset: 0.4,
                      color: '#19be6b' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: '#19be6b' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: '#19be6b',
                shadowBlur: 9,
                shadowOffsetY: 3
              }
            },
            itemStyle: {
              normal: {
                color: '#19be6b',
              }
            },
            emphasis: {
              // 鼠标hover上去的时候，拐点的颜色和样式
              itemStyle: {
                color: '#19be6b', //颜色
                borderColor: '#fff', //图形的描边颜色
                borderWidth: 1, // 描边的线宽
                shadowBlur: 0.5, // 图形的阴影大小
                shadowColor: '#19be6b', // 图形的阴影颜色
              },
            },
            smooth: false
          }
        ]
      };
      let timePreTransferOption = {
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          color: ["#F58080", "#47D8BE", "#F9A589"],
          right: '2%',
          top: '2%'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          height: '80%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: XData,
          axisLine: {
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)"
            }
          },
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: "#808695"
            }}    ,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
        }
        ],
        series: [
          {
            name: `${src_province}${src_isp ? '-'+src_isp : ''}-首包时间`,
            type: 'line',
            data: timePreTransferValueData.data,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            lineStyle: {
              normal: {
                width: 1,
                color: {
                  type: 'linear',
                  colorStops: [{
                    offset: 0,
                    color: '#19be6b' // 0% 处的颜色
                  },
                    {
                      offset: 0.4,
                      color: '#19be6b' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: '#19be6b' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: '#19be6b',
                shadowBlur: 9,
                shadowOffsetY: 3
              }
            },
            itemStyle: {
              normal: {
                color: '#19be6b',
              }
            },
            emphasis: {
              // 鼠标hover上去的时候，拐点的颜色和样式
              itemStyle: {
                color: '#19be6b', //颜色
                borderColor: '#fff', //图形的描边颜色
                borderWidth: 1, // 描边的线宽
                shadowBlur: 0.5, // 图形的阴影大小
                shadowColor: '#19be6b', // 图形的阴影颜色
              },
            },
            smooth: false
          }
        ]
      };
      let timeNameLookUpOption = {
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          color: ["#F58080", "#47D8BE", "#F9A589"],
          right: '2%',
          top: '2%'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          height: '80%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: XData,
          axisLine: {
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)"
            }
          },
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: "#808695"
            }}    ,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
        }
        ],
        series: [
          {
            name: `${src_province}${src_isp ? '-'+src_isp : ''}-DNS时间`,
            type: 'line',
            data: timeNameLookUpValueData.data,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            lineStyle: {
              normal: {
                width: 1,
                color: {
                  type: 'linear',
                  colorStops: [{
                    offset: 0,
                    color: '#19be6b' // 0% 处的颜色
                  },
                    {
                      offset: 0.4,
                      color: '#19be6b' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: '#19be6b' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: '#19be6b',
                shadowBlur: 9,
                shadowOffsetY: 3
              }
            },
            itemStyle: {
              normal: {
                color: '#19be6b',
              }
            },
            emphasis: {
              // 鼠标hover上去的时候，拐点的颜色和样式
              itemStyle: {
                color: '#19be6b', //颜色
                borderColor: '#fff', //图形的描边颜色
                borderWidth: 1, // 描边的线宽
                shadowBlur: 0.5, // 图形的阴影大小
                shadowColor: '#19be6b', // 图形的阴影颜色
              },
            },
            smooth: false
          }
        ]
      };
      let timeDownloadOption = {
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
          textStyle: {
            color: 'pink',
            fontSize: 16,
            fontWeight: 400
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          color: ["#F58080", "#47D8BE", "#F9A589"],
          right: '2%',
          top: '2%'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          height: '80%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: XData,
          axisLine: {
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)"
            }
          },
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: "#808695"
            }}    ,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(193, 207, 220, 1)',
            }
          },
        }
        ],
        series: [
          {
            name: `${src_province}${src_isp ? '-'+src_isp : ''}-下载时间`,
            type: 'line',
            data: timeDownloadValueData.data,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            lineStyle: {
              normal: {
                width: 1,
                color: {
                  type: 'linear',
                  colorStops: [{
                    offset: 0,
                    color: '#19be6b' // 0% 处的颜色
                  },
                    {
                      offset: 0.4,
                      color: '#19be6b' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: '#19be6b' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: '#19be6b',
                shadowBlur: 9,
                shadowOffsetY: 3
              }
            },
            itemStyle: {
              normal: {
                color: '#19be6b',
              }
            },
            emphasis: {
              // 鼠标hover上去的时候，拐点的颜色和样式
              itemStyle: {
                color: '#19be6b', //颜色
                borderColor: '#fff', //图形的描边颜色
                borderWidth: 1, // 描边的线宽
                shadowBlur: 0.5, // 图形的阴影大小
                shadowColor: '#19be6b', // 图形的阴影颜色
              },
            },
            smooth: false
          }
        ]
      };

      this.speedTrendDetailsChart.setOption(speedOption);
      this.succRateDetailsChart.setOption(succRateOption);
      this.sizeDownloadChart.setOption(sizeDownloadOption);
      this.timeTotalChart.setOption(timeTotalOption);
      this.timePreTransferChart.setOption(timePreTransferOption);
      this.timeNameLookUpChart.setOption(timeNameLookUpOption);
      this.timeDownloadChart.setOption(timeDownloadOption);

      this.speedTrendDetailsChart.resize();
      this.succRateDetailsChart.resize();
      this.sizeDownloadChart.resize();
      this.timeTotalChart.resize();
      this.timePreTransferChart.resize();
      this.timeNameLookUpChart.resize();
      this.timeDownloadChart.resize();
    },
    submit() {
      this.provinceSelect = []
      this.provinceIspSelect = []
      this.provinceIspProvinceSelect = []
      let formData = this.$_.cloneDeep(this.formData)
      formData.time[0] = this.$moment(formData.time[0]).format("YYYY-MM-DD HH:mm:ss")
      formData.time[1] = this.$moment(formData.time[1]).format("YYYY-MM-DD HH:mm:ss")
      let query_obj = {"name": `${formData.name}`, "task_id": `${formData.id}`}
      //`select name,SUM(case when http_code >= 400 or http_code < 200 then 1 else 0 end) as fail, SUM(case when http_code >= 200 or http_code < 400 then 1 else 0 end) as succ, (fail+succ) total, floor(avg(size_download),1) size_download, floor(avg(download_speed),1) avg_speed, floor(avg(time_start_transfer),1) time_start_transfer, floor(avg(time_pre_transfer),1) time_pre_transfer  from cassini.httpquality where name='${formData.name}' and t >= '${formData.time[0]}' and t <= '${formData.time[1]}' group by name FORMAT JSON`
      ckquery(`SELECT name, count() count,
        floor(sum(size_download)/sum(succ)/1024,2) avg_size_download,
        floor(sum(time_total)/sum(succ),2) avg_time_total,
        floor(sum(time_pre_transfer)/sum(succ),2) avg_time_pre_transfer,
        floor(sum(time_name_lookup)/sum(succ),2) avg_time_name_lookup,
        floor(sum(time_download)/sum(succ),2) avg_time_download,
        floor(sum(download_speed)/count(),2) avg_download_speed,
        floor(sum(succ)/count()*100,2) succ_rate
        FROM(select name,
            multiIf(success != 1, 0, size_download) AS size_download,
            multiIf(success != 1, 0, download_speed) AS download_speed,
            multiIf(success != 1, 0, time_total) AS time_total,
            multiIf(success != 1, 0, time_pre_transfer) AS time_pre_transfer,
            multiIf(success != 1, 0, time_name_lookup) AS time_name_lookup,
            multiIf(success != 1, 0, time_download) AS time_download,
            multiIf(success != 1, 0,1) AS succ
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
        and t >= '${formData.time[0]}' and t <= '${formData.time[1]}')
        group by name FORMAT JSON`)
          .then(resp=>{
            if (resp.data.length > 0) {
              this.overviewData.overview = resp.data[0]
            } else {
              this.overviewData.overview = {avg_download_speed: 0, avg_size_download: 0, avg_time_pre_transfer: 0, succ_rate: 0, count: 0}
            }
            this.renderOverview()
          })
          .catch(e => console.error(e))

      ckquery(`select ${this.taskTrendData.interval},
       floor(sum(download_speed)/count(),2) avg_download_speed
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
          and t >= '${formData.time[0]}' and t <= '${formData.time[1]}'
          and success = 1 group by t order by t FORMAT JSON`)
          .then(resp=>{
            this.overviewData.speed_trend = resp.data
            this.renderSpeedTrend()
          })
          .catch(e => console.error(e))

      ckquery(`select ${this.taskTrendData.interval},
       floor(SUM(CASE WHEN success = 1 THEN 1 ELSE 0 END)/count()*100,2) succ_rate
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
          and t >= '${formData.time[0]}' and t <= '${formData.time[1]}'
        group by t order by t FORMAT JSON`)
          .then(resp=>{
            this.overviewData.succ_rate_trend = resp.data
            this.renderSuccRateTrend()
          })
          .catch(e => console.error(e))

      ckquery(` select src_isp,
        floor(sum(download_speed)/count(),2) avg_download_speed
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
          and t >= '${formData.time[0]}' and t <= '${formData.time[1]}'
          and success = 1 group by src_isp order by avg_download_speed desc LIMIT 5 FORMAT JSON`)
          .then(resp=>{
            this.overviewData.speed_top5 = resp.data
            this.renderTop5()
          })
          .catch(e => console.error(e))

      ckquery(`select success, count() code_count
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
          and t >= '${formData.time[0]}' and t <= '${formData.time[1]}'
          and success != 1 group by success order by code_count desc LIMIT 5 FORMAT JSON`)
          .then(resp=>{
            this.overviewData.error_top5 = resp.data
            this.renderTop5()
          })
          .catch(e => console.error(e))

      ckquery(`SELECT src_province, count() count,
        floor(sum(size_download)/sum(succ)/1024,2) avg_size_download,
        floor(sum(time_total)/sum(succ),2) avg_time_total,
        floor(sum(time_pre_transfer)/sum(succ),2) avg_time_pre_transfer,
        floor(sum(time_name_lookup)/sum(succ),2) avg_time_name_lookup,
        floor(sum(time_download)/sum(succ),2) avg_time_download,
        floor(sum(download_speed)/count(),2) avg_download_speed,
        floor(sum(succ)/count()*100,2) succ_rate
        FROM(select src_province,
            multiIf(success != 1, 0, size_download) AS size_download,
            multiIf(success != 1, 0, download_speed) AS download_speed,
            multiIf(success != 1, 0, time_total) AS time_total,
            multiIf(success != 1, 0, time_pre_transfer) AS time_pre_transfer,
            multiIf(success != 1, 0, time_name_lookup) AS time_name_lookup,
            multiIf(success != 1, 0, time_download) AS time_download,
            multiIf(success != 1, 0,1) AS succ
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
        and t >= '${formData.time[0]}' and t <= '${formData.time[1]}')
        group by src_province order by succ_rate FORMAT JSON`)
          .then(resp=>{
            this.provinceList = []
            for (let i = 0; i < resp.data.length; i++) {
              this.provinceList.push({
                "value": resp.data[i].src_province,
              })
            }
            this.overviewData.province_details = resp.data
            this.overviewData.province_details_copy = resp.data
          })
          .catch(e => console.error(e))

      ckquery(`SELECT src_province,src_isp, count() count,
        floor(sum(size_download)/sum(succ)/1024,2) avg_size_download,
        floor(sum(time_total)/sum(succ),2) avg_time_total,
        floor(sum(time_pre_transfer)/sum(succ),2) avg_time_pre_transfer,
        floor(sum(time_name_lookup)/sum(succ),2) avg_time_name_lookup,
        floor(sum(time_download)/sum(succ),2) avg_time_download,
        floor(sum(download_speed)/count(),2) avg_download_speed,
        floor(sum(succ)/count()*100,2) succ_rate
        FROM(select src_province,src_isp,
            multiIf(success != 1, 0, size_download) AS size_download,
            multiIf(success != 1, 0, download_speed) AS download_speed,
            multiIf(success != 1, 0, time_total) AS time_total,
            multiIf(success != 1, 0, time_pre_transfer) AS time_pre_transfer,
            multiIf(success != 1, 0, time_name_lookup) AS time_name_lookup,
            multiIf(success != 1, 0, time_download) AS time_download,
            multiIf(success != 1, 0,1) AS succ
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
        and t >= '${formData.time[0]}' and t <= '${formData.time[1]}')
        group by src_province,src_isp order by succ_rate FORMAT JSON`)
          .then(resp=>{
            this.provinceIspList = [];
            for (let i = 0; i < resp.data.length; i++) {
              if (!this.provinceIspList.includes(resp.data[i].src_isp)) {
                  this.provinceIspList.push(resp.data[i].src_isp)
              }
            }

            this.provinceIspProvinceList = []
            for (let i = 0; i < resp.data.length; i++) {
              this.provinceIspProvinceList.push(resp.data[i].src_province)
            }

            this.overviewData.province_isp_details = resp.data
            this.overviewData.province_isp_details_copy = resp.data
          })
          .catch(e => console.error(e))
    },
    handleSelect() {
      // console.log(this.provinceSelect)
      if (this.provinceSelect.length == 0) {
        this.overviewData.province_details = this.overviewData.province_details_copy
        // this.overviewData.province_details = []
      } else {
        let province_details = []
        for (let i = 0; i < this.provinceSelect.length; i++) {
          for (let j = 0; j < this.overviewData.province_details_copy.length; j++) {
            if (this.provinceSelect[i] == this.overviewData.province_details_copy[j].src_province) {
              province_details.push(this.overviewData.province_details_copy[j])
            }
          }
        }
        this.overviewData.province_details = province_details
      }
    },
    handleProvinceIspSelect() {
      if (this.provinceIspSelect.length == 0 && this.provinceIspProvinceSelect.length == 0) {
        this.overviewData.province_isp_details = this.overviewData.province_isp_details_copy
      } else {
        let province_isp_tmp = []
        let province_tmp = []
        let province_isp_details = []
        if (this.provinceIspProvinceSelect.length > 0) {
          for (let i = 0; i < this.overviewData.province_isp_details_copy.length; i++) {
            if (this.provinceIspProvinceSelect.includes(this.overviewData.province_isp_details_copy[i].src_province)) {
              province_tmp.push(this.overviewData.province_isp_details_copy[i])
            }
          }

          if (this.provinceIspSelect.length > 0) {
            for (let i = 0; i < province_tmp.length; i++) {
              if (this.provinceIspSelect.includes(province_tmp[i].src_isp)) {
                province_isp_details.push(province_tmp[i])
              }
            }
          } else {
            province_isp_details = province_tmp
          }
        } else {
          for (let i = 0; i < this.overviewData.province_isp_details_copy.length; i++) {
            if (this.provinceIspSelect.includes(this.overviewData.province_isp_details_copy[i].src_isp)) {
              province_isp_tmp.push(this.overviewData.province_isp_details_copy[i])
            }
          }

          if (this.provinceIspProvinceSelect.length > 0) {
            for (let i = 0; i < province_isp_tmp.length; i++) {
              if (this.provinceIspProvinceSelect.includes(province_isp_tmp[i].src_province)) {
                province_isp_details.push(province_isp_tmp[i])
              }
            }
          } else {
            province_isp_details = province_isp_tmp
          }
        }

        this.overviewData.province_isp_details = province_isp_details
      }
    },
    taskTrandByInterval() {
      let formData = this.$_.cloneDeep(this.formData)
      // if (formData.name.length == 0) {
      //   return
      // }

      formData.time[0] = this.$moment(formData.time[0]).format("YYYY-MM-DD HH:mm:ss")
      formData.time[1] = this.$moment(formData.time[1]).format("YYYY-MM-DD HH:mm:ss")
      let query_obj = {"name": `${formData.name}`, "task_id": `${formData.id}`}
      ckquery(`select ${this.taskTrendData.interval},
       floor(sum(download_speed)/count(),2) avg_download_speed
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
          and t >= '${formData.time[0]}' and t <= '${formData.time[1]}'
          and success = 1 group by t order by t FORMAT JSON`)
          .then(resp=>{
            this.overviewData.speed_trend = resp.data
            this.renderSpeedTrend()
          })
          .catch(e => console.error(e))

      ckquery(`select ${this.taskTrendData.interval},
       floor(SUM(CASE WHEN success = 1 THEN 1 ELSE 0 END)/count()*100,2) succ_rate
        from cassini.httpquality
        where ${this.filterEmptyItem(query_obj)}
          and t >= '${formData.time[0]}' and t <= '${formData.time[1]}'
        group by t order by t FORMAT JSON`)
          .then(resp=>{
            this.overviewData.succ_rate_trend = resp.data
            this.renderSuccRateTrend()
          })
          .catch(e => console.error(e))
    },
    filterEmptyItem(obj) {
      let result = []
      for (let key in obj) {
        if (obj[key] && obj[key] != "undefined") {
          result.push(`${key} = '${obj[key]}'`)
        }
      }
      if (result.length > 1) {
        return result.join(' and ')
      } else {
        return result[0]
      }
    }
  },
  watch: {
    "detailData.interval": {
      handler(newName, oldName) {
        this.showDetailByInterval();
      },
      deep: true
    },
    "taskTrendData.interval": {
      handler(newName, oldName) {
        this.taskTrandByInterval();
      },
      deep: true
    },
  },
  created() {
    this.getNames()
  },
  mounted() {
    this.formData.time = [this.$moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'), this.$moment().format('YYYY-MM-DD 23:59:59')]
    this.overviewChart = this.$echarts.init(document.getElementById("overview"));
    this.succRateChart = this.$echarts.init(document.getElementById("succRate"));
    this.speedTrendChart = this.$echarts.init(document.getElementById("speedTrend"));
    this.slowlyTop5Chart = this.$echarts.init(document.getElementById("slowlyTop5"));
    this.errorTop5Chart = this.$echarts.init(document.getElementById("errorTop5"));
    this.speedTrendDetailsChart = this.$echarts.init(document.getElementById("speedTrendDetails"));
    this.succRateDetailsChart = this.$echarts.init(document.getElementById("succRateDetails"));
    this.sizeDownloadChart = this.$echarts.init(document.getElementById("sizeDownload"));
    this.timeTotalChart = this.$echarts.init(document.getElementById("timeTotal"));
    this.timePreTransferChart = this.$echarts.init(document.getElementById("timePreTransfer"));
    this.timeNameLookUpChart =this.$echarts.init(document.getElementById("timeNameLookUp"));
    this.timeDownloadChart =this.$echarts.init(document.getElementById("timeDownload"));
    this.renderOverview()
    this.renderTop5()
    this.renderSpeedTrend()
    this.renderSuccRateTrend()
    // this.submit()
  }
}
</script>

<style scoped>
.form1 .ivu-form-item {
  margin-bottom: 0;
}

.error {
  color: #ed4014;
}

.warning {
  color: #ff9900;
}

.info {
  color: #19be6b;
}
</style>